import React, {Suspense, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Route, Switch, withRouter} from "react-router-dom";
import {getMetaTagUi} from "./utils/httpHelper";
import {setCurrentPathname, setPrevLocation, getSeoData} from "./core/actions/appActions";
import PrePostLoginRoute from "./core/webRouteWrapper/PrePostLoginRoute";
import HomePage from "./home/home";
import technologyPartnership from "./technologyPartnership/technologyPartnership";
import SoftwareDevelopmentServices from "./softwareDevelopmentServices/softwareDevelopmentServices";
import pageNotFound from "./core/components/PageNotFound/pageNotFound";
import DLLoader from "./core/components/Loader/loader";
import AboutUs from "./legal/aboutUs/aboutUs";
import ContactUs from "./user/support/ContactUs";
import Header from "./core/layout/header/header";
import ToastMessage from "./core/components/toastMessage/toastMessage";



const AppNavigator = (props) => {

    const [seoMetaDataState, setSeoMetaDataState] = useState({
        seoMetaData: null,
        isMetaDataCallFired: false,
    });

    useEffect(() => {
        setTimeout(() => {
            windowLoadEvent();
        }, 1500); // with every page navigation we are scrolling page to top. to avoid those scroll events implemented the delay of 1500ms
        setCurrentPathname(props.location.pathname);
            getSeoData().then((res) => {
                if(res.success){
                    setSeoMetaDataState((prevState) => ({
                        ...prevState,
                        seoMetaData: res.data?.SEO,
                        isMetaDataCallFired: true
                    }))
                }
            })
    }, [props.location.pathname]);

    const windowLoadEvent = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'gtm.load'
        });
    };

    const {isMobileView} = props;

    const renderContent = () => {
        const {seoMetaData} = seoMetaDataState;

        return (
            <div className="w-100 bg-white" id=""
                 style={{
                     height: '100%',
                 }}>
                {getMetaTagUi(props.location.pathname, seoMetaData, false)}

                <Suspense fallback={(<DLLoader type={"screen"} isVisible={true}/>)}>
                    <Switch>
                        <PrePostLoginRoute exact path="/" component={HomePage}/>
                        <PrePostLoginRoute exact path="/services/technology-partnership" component={technologyPartnership}/>
                        <PrePostLoginRoute exact path="/services/custom-software-development" component={SoftwareDevelopmentServices}/>
                        <PrePostLoginRoute exact path="/about-us" component={AboutUs}/>
                        <PrePostLoginRoute exact path="/contact-us" component={ContactUs}/>
                        <Route path="*" component={pageNotFound}/>
                    </Switch>
                </Suspense>
                <ToastMessage/>
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <Header {...props}/>
        );
    };

    return (
        <div className={props.isMobileDevice ? 'innerWindowHeight' : 'vh-100'}>
            {renderHeader()}
            <div className='d-flex'
                 style={{
                     paddingTop: isMobileView ? '50px' : '80px',
                     height: '100%'
                 }}>
                {renderContent()}
            </div>

        </div>

    );
};


AppNavigator.propTypes = {};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    windowHeight: state.appState.deviceInfo.windowHeight,
    isDrawerOpen: state.appState.isDrawerOpen,
});

export default connect(mapStateToProps, {
    setPrevLocation
})(withRouter(AppNavigator));

