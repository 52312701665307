import React, {useEffect} from 'react';
import FutureNow from "../assets/img/software development services/futureIsNow.jpg";
import CustomApplication from "../assets/img/software development services/whyCustom.jpg";
import Check from "../assets/img/check .png";
import Footer from "../core/layout/footer/footer";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import "./softwareDevelopmentServices.css"

function SoftwareDevelopmentServices(props) {
    const {isMobileView, isLGView, isXSView, isSMView, isXLView} = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderUI = () => {
        if (isMobileView) {
            return (
                <>
                    <section id={'software-banner-mobile'}
                             className='software-banner-bg d-flex justify-content-center'>
                        <div className="block-container py-3">
                            <h1 className="text-center theme-font-page-title theme-font-bold">
                                Custom Software Development
                            </h1>
                        </div>
                    </section>

                    <section id={'empower-business-mobile'}>
                        <div className="block-container padding-block-top padding-block-bottom">
                            <div>
                                <h4 className="theme-font-content-md theme-font-light theme-content-line-height pb-40">
                                    The Future is Now
                                </h4>
                                <h2 className=" theme-font-section-title theme-font-bold pb-40">
                                    Empower Your Business with AI-Driven Custom Solutions
                                </h2>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                    Ride the AI wave and unlock the full potential of your business with our custom
                                    solutions. The current state of AI enables you to implement ideas like never before.
                                </h4>
                                <div className="d-flex align-items-start" style={{gap: 20}}>
                                    <img src={Check}
                                         height={20}
                                         width={20}
                                         className="mt-1"
                                    />
                                    <div className="d-flex flex-column" style={{gap: 16}}>
                                        <h3 className="theme-font-section-header theme-font-normal theme-content-line-height">
                                            Ride the AI Wave
                                        </h3>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Leverage custom solutions that combine large language models like OpenAI
                                            with your business data.
                                        </h4>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start" style={{gap: 20}}>
                                    <img src={Check}
                                         height={20}
                                         width={20}
                                         className="mt-1"
                                    />
                                    <div className="d-flex flex-column" style={{gap: 16}}>
                                        <h3 className="theme-font-section-header theme-font-normal">
                                            Immersive Conversational Abilities
                                        </h3>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Integrate ChatGPT-like abilities seamlessly into your operations.
                                        </h4>
                                    </div>
                                </div>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                    Interested in exploring how AI-driven software development can revolutionize your
                                    business? Let's discuss your ideas!"
                                </h4>
                            </div>
                            <Link to={"/contact-us"}>
                            <button className="button-style">
                                Contact Us
                            </button>
                            </Link>
                            <div className="col-sm-6 col-12 pt-40">
                                <img src={FutureNow}
                                     className='img-fluid'/>
                            </div>
                        </div>
                    </section>

                    <section id={'web-application-development-mobile'}>
                        <div style={{backgroundColor: '#F3F8FD'}}
                             className="block-container padding-block-top padding-block-bottom">
                            <h2 className="theme-font-section-title theme-font-bold  pb-40">
                                Why custom web and mobile applications?
                            </h2>
                            <div>
                                <div className="d-flex align-items-start" style={{gap: 20}}>
                                    <img src={Check}
                                         height={20}
                                         width={20}
                                         className="mt-1"
                                    />
                                    <div className="d-flex flex-column" style={{gap: 16}}>
                                        <h3 className="theme-font-section-header theme-font-normal theme-content-line-height">
                                            Escape Cookie-Cutter Solutions
                                        </h3>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Tired of generic solutions? Custom web application development aligns with
                                            your unique business.
                                        </h4>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start" style={{gap: 20}}>
                                    <img src={Check}
                                         height={20}
                                         width={20}
                                         className="mt-1"
                                    />
                                    <div className="d-flex flex-column" style={{gap: 16}}>
                                        <h3 className="theme-font-section-header theme-font-normal theme-content-line-height">
                                            Soar Above the Competition
                                        </h3>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Achieve tailored customer experiences that outshine competitors and make
                                            lasting impressions.
                                        </h4>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start" style={{gap: 20}}>
                                    <img src={Check}
                                         height={20}
                                         width={20}
                                         className="mt-1"
                                    />
                                    <div className="d-flex flex-column" style={{gap: 16}}>
                                        <h3 className="theme-font-section-header theme-font-normal theme-content-line-height">
                                            Master Your Data
                                        </h3>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            With custom development, take control of your data, ensuring privacy and
                                            control.
                                        </h4>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start" style={{gap: 20}}>
                                    <img src={Check}
                                         height={20}
                                         width={20}
                                         className="mt-1"
                                    />
                                    <div className="d-flex flex-column" style={{gap: 16}}>
                                        <h3 className="theme-font-section-header theme-font-normal theme-content-line-height">
                                            Freedom from Limitations
                                        </h3>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Customize every aspect without platform constraints, embracing innovation on
                                            your terms. Watch your solution evolve with your business, adapting to
                                            changes and delivering ongoing returns.
                                        </h4>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start" style={{gap: 20}}>
                                    <img src={Check}
                                         height={20}
                                         width={20}
                                         className="mt-1"
                                    />
                                    <div className="d-flex flex-column" style={{gap: 16}}>
                                        <h3 className="theme-font-section-header theme-font-normal theme-content-line-height">
                                            Investing in the Future
                                        </h3>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Custom web application development is a long-term investment, not just an
                                            expense. It demands patience but delivers lasting value.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-12">
                                <img src={CustomApplication}
                                     className='img-fluid'/>
                            </div>
                        </div>
                    </section>

                    <section id={'app-vision-mobile'}>
                        <div className="block-container padding-block-top padding-block-bottom">
                            <h2 className="theme-font-section-title theme-font-bold  pb-40">
                                Discover our expertise in web application development
                            </h2>
                            <div className="d-flex flex-column"
                                 style={{gap: 40}}>
                                <div className="panel"
                                     style={{flex: 1}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Device-Friendly Design
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Responsive designs for a seamless experience across any device - mobile, tablet, laptop, or desktop.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{flex: 1}}>
                                    <div className="top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Rapid Feature Deployment
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Outpace competitors with swift feature unveiling.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{flex: 1}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Flawless UX
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Expertly curated UI-UX for a perfect customer journey.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{flex: 1}}>
                                    <div className="top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Flexible Scaling
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Effortless scalability from single-machine to microservices clusters. Expand at your own pace!
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <h2 className="theme-font-section-title theme-font-bold padding-block-top pb-40">
                                Discover our expertise in mobile application development
                            </h2>
                            <div className="d-flex flex-column" style={{gap: 40}}>
                                <div className="panel"
                                     style={{flex: 1}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Cross-Platform Expertise
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Deep expertise in both iOS and Android ecosystems, tackling common
                                            challenges head-on.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{flex: 1}}>
                                    <div className="top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Rapid Time-to-Market
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Accelerate time-to-market with our pre-built frameworks for push
                                            notifications, in-app messages, social interactions and many more.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{flex: 1}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Seamless Customer Journeys
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Craft a flawless customer journey through unified UI-UX experiences, curated
                                            by our expert team.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{flex: 1}}>
                                    <div className="top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            App Stability
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Robust crash detection and ANR prevention framework for enhanced app
                                            stability.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'unleash-expertise-mobile'}
                             style={{backgroundColor: '#F3F8FD'}}>
                        <div className="block-container padding-block-bottom padding-block-top">
                            <h4 className="theme-font-content-md  theme-font-light theme-content-line-height pb-40">
                                Unleash Expertise
                            </h4>
                            <h2 className="theme-font-section-title theme-font-semiBold pb-40 ">
                                <span style={{color: '#1671DD'}}> Staff Augmentation </span>
                                for Your Project Success
                            </h2>
                            <h4 className="theme-font-content-md theme-font-light theme-content-line-height  pb-60">
                                Elevate your team's capabilities with our comprehensive staff augmentation services. Whether
                                you require
                            </h4>
                            <div className="text-center">
                                <div className="linear-gradient-border pb-60">
                                    <div className="d-flex flex-column  align-items-center  "
                                         style={{gap: 24}}>
                                        <h3 className="theme-font-section-header theme-font-light">Top-notch </h3>
                                        <h3 className="theme-font-section-header theme-font-semiBold">Developers</h3>
                                    </div>
                                </div>
                                <div className="linear-gradient-border pt-60 pb-60">
                                    <h3 className=" d-flex flex-column justify-content-center align-items-center  "
                                        style={{gap: 24}}>
                                        <h3 className="theme-font-section-header theme-font-light">Creative</h3>
                                        <h3 className="theme-font-section-header theme-font-semiBold">UI-UX
                                            designers</h3>
                                    </h3>
                                </div>
                                <div className="linear-gradient-border pt-60 pb-60">
                                    <h3 className="d-flex flex-column justify-content-center align-items-center"
                                        style={{gap: 24}}>
                                        <h3 className="theme-font-section-header theme-font-light">Insightful</h3>
                                        <h3 className="theme-font-section-header theme-font-semiBold">Business
                                            Analysts</h3>
                                    </h3>
                                </div>
                                <div className=" pt-60">
                                    <h3 className="d-flex  flex-column justify-content-center align-items-center "
                                        style={{gap: 24}}>
                                        <h3 className="theme-font-section-header theme-font-light">Meticulous </h3>
                                        <h3 className="theme-font-section-header theme-font-semiBold">Testers</h3>
                                    </h3>
                                </div>
                            </div>
                            <h4 className="theme-font-content-md theme-font-light theme-content-line-height"
                            style={{paddingTop:60}}>
                                we have the expertise to seamlessly integrate these roles into your projects.
                            </h4>
                        </div>
                    </section>

                    <section id={'contact-us'}
                             className="linear-gradient-background padding-block-bottom padding-block-top text-center px-5">
                        <h2 className="theme-font-section-title text-center theme-font-semiBold pb-60 theme-text-white">
                            Ready to learn more about our services?
                        </h2>
                        <h4 className="theme-font-content-md theme-font-light theme-content-line-height pb-60 theme-text-white">
                            We are here to help you succeed. Get in touch with us to explore our offerings.
                        </h4>
                        <Link to={"/contact-us"}>
                        <button className="button-style-white ">
                            Contact Us
                        </button>
                        </Link>
                    </section>

                </>
            )
        }

        return (
            <div>
                <section id={'software-banner-desktopView'}
                         className='software-banner-bg'>
                    <h1 className="padding-block-left theme-font-page-title theme-font-bold">
                        Custom Software Development
                    </h1>
                </section>

                <section id={'empower-business-desktop'}>
                    <div className="block-container padding-block-top padding-block-bottom">
                        <div className="d-flex">
                            <div className="padding-block-right d-flex align-items-center "
                                 style={{flex: 5}}>
                                <img alt={''}
                                     src={FutureNow}
                                     className='img-fluid'/>
                            </div>
                            <div style={{flex: 7}}>
                                <h4 className="theme-font-section-header theme-font-light theme-content-line-height pb-4">
                                    The Future is Now
                                </h4>
                                <h2 className="theme-font-section-title theme-font-semiBold pb-40">
                                    Empower Your Business with
                                    <span style={{color: '#1671DD'}}> AI-Driven </span>
                                    Custom Solutions
                                </h2>
                                <h4 className="theme-font-content-md theme-font-light theme-content-line-height pb-40">
                                    Ride the AI wave and unlock the full potential of your business with our custom
                                    solutions. The current state of AI enables you to implement ideas like never before.
                                </h4>
                                <div className="d-flex align-items-start"
                                     style={{gap: 20}}>
                                    <img src={Check}
                                         height={20}
                                         width={20}
                                         className="mt-1"
                                    />
                                    <div className="d-flex flex-column"
                                         style={{gap: 16}}>
                                        <h3 className="theme-font-section-header theme-font-normal ">
                                            Ride the AI Wave
                                        </h3>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Leverage custom solutions that combine large language models like OpenAI
                                            with your business data.
                                        </h4>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start"
                                     style={{gap: 20}}>
                                    <img src={Check}
                                         height={20}
                                         width={20}
                                         className="mt-1"
                                    />
                                    <div className="d-flex flex-column"
                                         style={{gap: 16}}>
                                        <h3 className="theme-font-section-header theme-font-normal ">
                                            Immersive Conversational Abilities
                                        </h3>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Integrate ChatGPT-like abilities seamlessly into your operations.
                                        </h4>
                                    </div>
                                </div>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-60">
                                    Interested in exploring how AI-driven software development can revolutionize your
                                    business? Let's discuss your ideas!
                                </h4>
                                <Link to={"/contact-us"}>
                                <button className="button-style">
                                    Contact Us
                                </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'web-application-development-desktop'}>
                    <div style={{backgroundColor: '#F3F8FD'}}
                         className="block-container padding-block-top padding-block-bottom">
                        <div className="d-flex">
                            <div style={{flex: 7}}>
                                <h2 className="theme-font-section-title theme-font-semiBold pb-40">
                                    Why <span style={{color: 'var(--secondary)'}}> custom </span>
                                    web and mobile applications?
                                </h2>
                                <div className="d-flex align-items-start"
                                     style={{gap: 20}}>
                                    <img src={Check}
                                         height={20}
                                         width={20}
                                         className="mt-1"
                                    />
                                    <div className="d-flex flex-column"
                                         style={{gap: 16}}>
                                        <h3 className="theme-font-section-header theme-font-normal ">
                                            Escape Cookie-Cutter Solutions
                                        </h3>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Tired of generic solutions? Custom web application development aligns with
                                            your unique business.
                                        </h4>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start"
                                     style={{gap: 20}}>
                                    <img src={Check}
                                         height={20}
                                         width={20}
                                         className="mt-1"
                                    />
                                    <div className="d-flex flex-column"
                                         style={{gap: 16}}>
                                        <h3 className="theme-font-section-header theme-font-normal ">
                                            Soar Above the Competition
                                        </h3>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Achieve tailored customer experiences that outshine competitors and make
                                            lasting impressions.
                                        </h4>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start"
                                     style={{gap: 20}}>
                                    <img src={Check}
                                         height={20}
                                         width={20}
                                         className="mt-1"
                                    />
                                    <div className="d-flex flex-column"
                                         style={{gap: 16}}>
                                        <h3 className="theme-font-section-header theme-font-normal ">
                                            Master Your Data
                                        </h3>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            With custom development, take control of your data, ensuring privacy and
                                            control.
                                        </h4>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start"
                                     style={{gap: 20}}>
                                    <img src={Check}
                                         height={20}
                                         width={20}
                                         className="mt-1"
                                    />
                                    <div className="d-flex flex-column"
                                         style={{gap: 16}}>
                                        <h3 className="theme-font-section-header theme-font-normal theme-content-line-height">
                                            Freedom from Limitations
                                        </h3>
                                        <h4 className="theme-font-content theme-font-normal  pb-40">
                                            Customize every aspect without platform constraints, embracing innovation on
                                            your terms. Watch your solution evolve with your business, adapting to
                                            changes and delivering ongoing returns.
                                        </h4>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start"
                                     style={{gap: 20}}>
                                    <img src={Check}
                                         height={20}
                                         width={20}
                                         className="mt-1"
                                    />
                                    <div className="d-flex flex-column"
                                         style={{gap: 16}}>
                                        <h3 className="theme-font-section-header theme-font-normal">
                                            Investing in the Future
                                        </h3>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Custom web application development is a long-term investment, not just an
                                            expense. It demands patience but delivers lasting value.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="padding-block-left d-flex align-items-center "
                                 style={{flex: 5}}>
                                <img alt={''}
                                     src={CustomApplication}
                                     className='img-fluid'/>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'mobile-app-development-desktop'}>
                    <div className="block-container padding-block-top padding-block-bottom">
                        <h2 className="theme-font-section-title theme-font-semiBold text-center pb-60">
                            Discover <span style={{color: '#1671DD'}}> our expertise </span>
                            in web application development
                        </h2>
                        <div className="d-flex padding-block-bottom"
                             style={{gap: 20}}>
                            <div className="panel"
                                 style={{flex: 1}}>
                                <div className=" top-strip-blue"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-header theme-font-semiBold py-4 panel-header-text-height" >
                                        Device-Friendly Design
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                        Responsive designs for a seamless experience across any device - mobile, tablet,
                                        laptop, or desktop.
                                    </h4>
                                </div>
                            </div>
                            <div className="panel"
                                 style={{flex: 1}}>
                                <div className="top-strip-blue"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-header theme-font-semiBold py-4 panel-header-text-height" >
                                        Rapid Feature Deployment
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                        Outpace competitors with swift feature unveiling.
                                    </h4>
                                </div>
                            </div>
                            <div className="panel"
                                 style={{flex: 1}}>
                                <div className=" top-strip-blue"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-header theme-font-semiBold py-4 panel-header-text-height" >
                                        Flawless UX
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40 ">
                                        Expertly curated UI-UX for a perfect customer journey.
                                    </h4>
                                </div>
                            </div>
                            <div className="panel"
                                 style={{flex: 1}}>
                                <div className="top-strip-blue"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-header theme-font-semiBold py-4 panel-header-text-height" >
                                        Flexible Scaling
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40 ">
                                        Effortless scalability from single-machine to microservices clusters. Expand at
                                        your own pace!
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div style={{height:100}}/>

                        <h2 className="theme-font-section-title theme-font-semiBold text-center pb-60">
                            Discover <span style={{color: '#1671DD'}}>our expertise </span> in
                            mobile application development
                        </h2>
                        <div className="d-flex"
                             style={{gap: 20}}>
                            <div className="panel"
                                 style={{flex: 1}}>
                                <div className=" top-strip-blue"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-header theme-font-semiBold py-4 panel-header-text-height">
                                        Cross-Platform Expertise
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                        Deep expertise in both iOS and Android ecosystems, tackling common
                                        challenges head-on.
                                    </h4>
                                </div>
                            </div>
                            <div className="panel"
                                 style={{flex: 1}}>
                                <div className="top-strip-blue"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-header theme-font-semiBold py-4 panel-header-text-height">
                                        Rapid Time-to-Market
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                        Accelerate time-to-market with our pre-built frameworks for push
                                        notifications, in-app messages, social interactions and many more.
                                    </h4>
                                </div>
                            </div>
                            <div className="panel"
                                 style={{flex: 1}}>
                                <div className=" top-strip-blue"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-header theme-font-semiBold py-4 panel-header-text-height">
                                        Seamless Customer Journeys
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                        Craft a flawless customer journey through unified UI-UX experiences, curated
                                        by our expert team.
                                    </h4>
                                </div>
                            </div>
                            <div className="panel"
                                 style={{flex: 1}}>
                                <div className="top-strip-blue"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-header theme-font-semiBold py-4 panel-header-text-height">
                                        App Stability
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40 ">
                                        Robust crash detection and ANR prevention framework for enhanced app
                                        stability.
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'custom-staff-augmentation-desktop'}
                         style={{backgroundColor: '#F3F8FD'}}>
                    <div className="block-container padding-block-bottom padding-block-top">
                        <h4 className="theme-font-section-header text-center theme-font-light theme-content-line-height pb-4">
                            Unleash Expertise
                        </h4>
                        <h2 className="theme-font-section-title theme-font-semiBold pb-40 text-center">
                            <span style={{color: '#1671DD'}}> Staff Augmentation </span> for Your Project Success
                        </h2>
                        <h4 className="theme-font-content-md theme-font-light theme-content-line-height text-center pb-60">
                            Elevate your team's capabilities with our comprehensive staff augmentation services. Whether
                            you require
                        </h4>

                        <div className="row pb-40" >
                            <div className="col-3 px-0">
                                <h3 className="theme-font-section-header theme-font-light  text-center linear-gradient-border-top pb-4 h-100"
                                style={{paddingTop:60}}>
                                    Top-notch
                                </h3>
                            </div>
                            <div className="col-3 px-0">
                                <h3 className="theme-font-section-header theme-font-light  text-center linear-gradient-border-top pb-4 h-100"
                                    style={{paddingTop:60}}>
                                    Creative
                                </h3>
                            </div>
                            <div className="col-3 px-0">
                                <h3 className="theme-font-section-header theme-font-light text-center linear-gradient-border-top pb-4 h-100"
                                    style={{paddingTop:60}}>
                                    Insightful
                                </h3>
                            </div>
                            <div className="col-3 px-0">
                                <h3 className="theme-font-section-header theme-font-light text-center  pb-4 h-100"
                                    style={{paddingTop:60}}>
                                    Meticulous
                                </h3>
                            </div>
                            <div className="col-3 px-0">
                                <h4 className="theme-font-section-header px-1 theme-font-semiBold text-center pb-60 linear-gradient-border-bottom h-100" >
                                    Developers
                                </h4>
                            </div>
                            <div className="col-3 px-0">
                                <h4 className="theme-font-section-header px-3 theme-font-semiBold text-center pb-60 linear-gradient-border-bottom h-100" >
                                    UI-UX designers
                                </h4>
                            </div>
                            <div className="col-3 px-0">
                                <h4 className="theme-font-section-header px-1 theme-font-semiBold text-center pb-60 linear-gradient-border-bottom h-100" >
                                    Business Analysts
                                </h4>
                            </div>
                            <div className="col-3 px-0">
                                <h4 className="theme-font-section-header px-1 theme-font-semiBold text-center pb-60  h-100">
                                    Testers
                                </h4>
                            </div>
                        </div>


                        <h4 className="theme-font-content-md theme-font-light theme-content-line-height text-center">
                            We have the expertise to seamlessly integrate these roles into your projects.
                        </h4>
                    </div>
                </section>

                <section id={'contact-us'}
                         className="linear-gradient-background padding-block-bottom padding-block-top text-center px-5">
                    <h2 className="theme-font-section-title text-center theme-font-semiBold pb-60 theme-text-white">
                        Ready to learn more about our services?
                    </h2>
                    <h4 className="theme-font-content-md theme-font-light theme-content-line-height pb-60 theme-text-white">
                        We are here to help you succeed. Get in touch with us to explore our offerings.
                    </h4>
                    <Link to={"/contact-us"}>
                    <button className="button-style-white ">
                        Contact Us
                    </button>
                    </Link>
                </section>
            </div>
        )
    };

    return (
        <div className="w-100">
            {renderUI()}
            {/*Section 10*/}
            <Footer {...props}/>
        </div>
    );
};

SoftwareDevelopmentServices.propTypes = {};

SoftwareDevelopmentServices.defaultProps = {};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(SoftwareDevelopmentServices));
