import React, {useEffect, useState} from 'react';
import {isEmpty} from "../../../utils/validations";
import {isValidEmail, redirectTo} from "../../../utils/helper";
import {subscribeNewsLetter} from "../actions";
import {showToastMessage} from "../../../core/actions/appActions";
import {withTranslation} from "react-i18next";
import store from "../../../store";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import appTheme from "../../../assets/appTheme";
import Logo from "../../../assets/img/logo-dark.png";

function Footer(props) {
    const {isMobileView, isLGView, isXSView, isSMView} = props;

    const [homePageState, setHomepageState] = useState({
        emailId: '',
        emailIdError: ''
    });

    const handleChange = (name, value) => {
        setHomepageState((previewState) => ({
            ...previewState,
            [name]: value,
            [name + 'Error']: ""
        }))
    };

    const validateChanges = (field) => {
        const {emailId} = homePageState;
        const {t} = props;
        if (field === 'emailId') {
            if (isEmpty(emailId)) {
                setHomepageState((previewState) => ({
                    ...previewState,
                    [field + 'Error']: t('LABEL_ERROR_EMAIL')
                }));

                return false
            }

            if (emailId.length > 50) {
                setHomepageState(previewState => ({
                    ...previewState,
                    [field + 'Error']: t('LABEL_ERROR_LIMIT_50')

                }));
                return false;
            }

            if (!isValidEmail(emailId, true)) {
                setHomepageState(previewState => ({
                    ...previewState,
                    [field + 'Error']: t('LABEL_ERROR_EMAIL_VALIDATION')

                }));
                return false;
            }
        }
        return true
    };

    const onSubmit = () => {
        const {emailId} = homePageState;

        if (!validateChanges('emailId')) {
            return
        }

        let data = {
            emailId: emailId
        };

        subscribeNewsLetter(data).then((res) => {
            if (res.success) {
                store.dispatch(showToastMessage('success', 'Thank you for subscribing. You will hear from us shortly.'));
                setHomepageState(previewState => ({
                    ...previewState,
                    emailId: ''
                }))
            }
        })

    };

    const onBlurInputText = () => {
        validateChanges('emailId')
    };


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderUI = () => {
        if (isMobileView) {
            return (
                <>
                    <section id={'footer-mobile'} className="footer-bg">
                        <div className="block-container">
                            <div className="py-4">
                                <img src={Logo}
                                     className="img-fluid"
                                     height={62}
                                     width={253}
                                     style={{paddingLeft: -15}}/>
                            </div>
                                <div>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height">
                                        Technology company that helps businesses and startups in
                                        conceptualization and
                                        implementation of future ready ideas</h4>
                                </div>
                                <div className="d-flex flex-column" style={{paddingTop:30, paddingBottom:30,gap:10}}>
                                    <Link className='theme-font-content cursor'
                                          style={{color: appTheme.linkStyle.color, textDecoration:'none'}}
                                          to={"/about-us"}>
                                        About Us
                                    </Link>
                                    <Link className=' theme-font-content cursor'
                                          style={{color: appTheme.linkStyle.color, textDecoration:'none'}}
                                          to={"/contact-us"}>
                                        Contact Us
                                    </Link>
                                </div>
                            <div className="d-flex align-items-center mb-3">
                                <a href="https://in.linkedin.com/company/deccanlogic" target="_blank">
                                    <i className="fa-brands fa-linkedin-in theme-text-primary"
                                       style={{fontSize: 20, paddingRight: "22px"}}/>
                                </a>
                            </div>
                            <div style={{borderBottom:'1px solid #666666'}}/>

                        </div>
                        <div className="d-flex justify-content-center theme-font-content"
                             style={{paddingTop:7, paddingBottom:20}}>
                            <div>© Copyright 2023 DeccanLogic Pvt Ltd</div>
                        </div>
                    </section>
                </>
            )
        }

        return (
            <section id={'footer-desktop'} className="footer-bg" >
                <div className="block-container" style={{height:251}}>

                    <div className="py-4" >
                        <img src={Logo}
                             className="img-fluid"
                             height={62}
                             width={253}
                             style={{paddingLeft: -15}}/>
                    </div>
                    <div className="d-flex justify-content-between pb-4">
                        <div>
                            <h4 className="theme-font-content theme-font-normal theme-content-line-height">
                                Technology company that helps businesses and startups in <br/>
                                conceptualization and
                                implementation of future ready ideas</h4>
                        </div>
                        <div className="d-flex flex-column" style={{gap:10}}>
                            <Link className='theme-font-semiBold theme-font-content'
                                  style={{color: appTheme.linkStyle.color, textDecoration:'none'}}
                                  to={"/about-us"}>
                                About Us
                            </Link>
                            <Link className='theme-font-semiBold theme-font-content'
                                  style={{color: appTheme.linkStyle.color, textDecoration:'none'}}
                                  to={"/contact-us"}>
                                Contact Us
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex align-items-center" style={{marginBottom:20}}>
                        <a href="https://in.linkedin.com/company/deccanlogic" target="_blank">
                            <i className="fa-brands fa-linkedin-in theme-text-primary"
                               style={{fontSize: 20, paddingRight: "22px"}}/>
                        </a>
                    </div>


                    <div style={{borderBottom:'1px solid #666666'}}/>
                    <div className="d-flex justify-content-center theme-font-content"
                         style={{paddingTop:5, paddingBottom:10}}>
                        <div>© Copyright 2023 DeccanLogic Pvt Ltd</div>
                        <div className="mx-3 my-1" style={{borderRight:'1px solid #333333'}}/>
                        <div>All Rights Reserved</div>
                    </div>
                </div>
            </section>
        )
    };
    return (
        <div>
            {renderUI()}
        </div>
    );
}

export default connect()(withTranslation('translations')(Footer));
